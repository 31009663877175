import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import { APP_TITLE, APP_META_DESCRIPTION } from './constants.js';

const Wizard = lazy(() => import('../wizard/component.js'));

const convertPngToWebp = (pngUrl) => {
  return pngUrl.replace("png", "webp");
}

const isTouchDevice = () => {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0))
     && window.matchMedia("(pointer: coarse)").matches;
}

const App = ({
  goToSectionIndex,
  metaDescription,
  metaTitle,
  noIndex,
  pageType,
  hidePriceFilter,
  hideNSFWToggle,
  showNsfw,
  imageSrcOne,
  imageSrcTwo,
  title,
}) => {
  const isSmallMobile = window.innerWidth <= 375;
  const isMobile = window.innerWidth < 500 || isTouchDevice();
  const isTablet = window.innerWidth > 500 && isTouchDevice();
  const isLaptop = (window.innerWidth >= 768) && (window.innerWidth <= 1599);
  const isDesktop = window.innerWidth >= 1600;
  const showNsfwGlobal = localStorage.getItem('showNsfwGlobal') === 'true';
  localStorage.setItem('isSmallMobile', isSmallMobile);
  localStorage.setItem('isMobile', isMobile);
  localStorage.setItem('isTablet', isTablet);
  localStorage.setItem('isLaptop', isLaptop);
  localStorage.setItem('isDesktop', isDesktop);
  localStorage.setItem('showNsfwGlobal', showNsfwGlobal);

  let imagePreloads;
  if (pageType === "StaticResults") {
    imagePreloads = [
      convertPngToWebp(imageSrcOne),
      convertPngToWebp(imageSrcTwo),
    ];
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle || APP_TITLE}</title>
        <meta name="description" content={metaDescription || APP_META_DESCRIPTION} />
        {noIndex &&
          <meta name="robots" content="noindex,follow" />
        }
        {imagePreloads &&
          imagePreloads.map((imageUrl) => 
              <link
                key={imageUrl}
                rel="preload" 
                as="image"
                href={imageUrl}
              />
          )
        }     
      </Helmet>
      <Suspense fallback={<div></div>}>
        <Wizard 
          goToSectionIndex={goToSectionIndex}
          metaDescription={metaDescription}
          pageType={pageType}
          hidePriceFilter={hidePriceFilter}
          hideNSFWToggle={hideNSFWToggle}
          showNsfw={showNsfw}
          imageSrcOne={imageSrcOne}
          imageSrcTwo={imageSrcTwo}
          title={title}
        />
      </Suspense>
    </>
  );
}

export default App;
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './containers/error-boundary/component.js';
import App from 'containers/app/component.js';
import { staticResultsPages, quizResultsPages, websitePageRoutes } from 'utils/constants.js';
import 'index.scss';

// Handle ChunkLoadError globally
window.addEventListener('error', (event) => {
	if (event.message.includes('ChunkLoadError')) {
			console.error('ChunkLoadError detected, reloading the page...');
			window.location.reload(); // Force refresh to resolve the issue
	}
});

const ErrorPage = lazy(() => import('containers/pages/error-page/component.js'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Routes>
			{staticResultsPages.map(route => {
				const StaticResultsComponent = 
				<ErrorBoundary>
					<App
						hidePriceFilter={route.hidePriceFilter}
						hideNSFWToggle={route.hideNSFWToggle}
						imageSrcOne={route.imageSrcOne}
						imageSrcTwo={route.imageSrcTwo}
						metaDescription={route.metaDescription}
						metaTitle={route.metaTitle}
						showNsfw={route.showNsfw}
						title={route.title}
						pageType="StaticResults"
					/>
				</ErrorBoundary>;
				return (
					<Route
						path={route.path} 
						element={StaticResultsComponent} 
						key={route.path}>
					</Route>
				);
			})}

			{quizResultsPages.map(route => {
				const QuizResultsComponent = 
				<ErrorBoundary>
					<App
						metaDescription={route.metaDescription}
						metaTitle={route.metaTitle}
						noIndex
						pageType="Results"
					/>
				</ErrorBoundary>
				return (
					<Route
						path={route.path} 
						element={QuizResultsComponent} 
						key={route.path}>
					</Route>
				);
			})}

			{websitePageRoutes.map(route => {
				const WebsitePageComponent = 
				<ErrorBoundary>
					<App
						metaDescription={route.metaDescription}
						metaTitle={route.metaTitle}
						noIndex={route.noIndex}
						pageType={route.pageType}
						goToSectionIndex={route.goToSectionIndex}
					/>
				</ErrorBoundary>
				return (
					<Route
						path={route.path} 
						element={WebsitePageComponent} 
						key={route.path}>
					</Route>
				);
			})}
			<Route 
				path="/error" 
				element={
					<Suspense fallback={<div />}>
							<ErrorPage />
					</Suspense>
				} 
			/>
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	</BrowserRouter>
);

// hides console warnings in prod
function noop() {}
if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  //console.error = noop;
}
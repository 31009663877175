import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { ACCOUNT_API_URL } from 'contexts/authContext';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("ErrorBoundary caught an error:", error, info);

    // Call the standalone logging method
    this.sendLogToBackend("error", "Error caught by ErrorBoundary", { error, info });

    this.setState({ error, errorInfo: info });
  }

  sendLogToBackend = async (level, message, metadata = {}) => {
    try {
      console.log(`[${level.toUpperCase()}] ${message}`, metadata); // Debug the metadata shape
  
      await axios.post(`${ACCOUNT_API_URL}logs`, {
        level,
        message,
        metadata, // Send metadata as an object
      });
    } catch (e) {
      console.error("Failed to send log to backend:", e);
    }
  };
  

  render() {
    if (this.state.hasError) {
      return <Navigate to="/error" replace />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
